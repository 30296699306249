import Header from '../globals/header';

class Anchor {
  constructor($el) {
    this.$el = $el;

    this.init();
  }

  init() {
    console.log('Anchor initialized', this);
    this.$el.on('click', this.handleClick);
  }

  handleClick(e) {

    const $el = $(e.currentTarget);
    const href = $el.attr('href');
    const target = href.split('#')[1];
    const $target = $(`#${target}`);

    if($target.length) {
      e.preventDefault();
      Header.closeMenu();
      $('html, body').animate({
        scrollTop: $target.offset().top
      }, 1000);
    }
  }
}

export default Anchor;