class Header {
  stateClasses = {
    open: 'nav-open',
  }
  constructor($el) {
    this.$html = $('html');
    this.$el = $el;
    this.$hamburger = this.$el.find('[data-header="hamburger"]');
    this.isOpen = false;
    this.init();
  }

  init() {
    console.log('Header initialized', this);
    this.$hamburger.on('click', this.onHamburgerClick);
  }

  onHamburgerClick = () => {
    if(this.isOpen) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  openMenu = () => {
    this.$html.addClass(this.stateClasses.open);
    this.isOpen = true;
  }

  closeMenu = () => {
    this.$html.removeClass(this.stateClasses.open);
    this.isOpen = false;
  }
}

export default new Header($('[data-header="el"]'));