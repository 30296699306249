import '../css/app.scss';

/** Global Components */
import Header from './globals/header';

import Slider from "./components/slider";
import Anchor from './components/anchor';

$(() => {
	const $sliders = $('[data-slider="el"]');

	$sliders.each((index, el) => {
		new Slider($(el));
	});

	const $anchors = $('[data-anchor="el"]');

	$anchors.each((index, el) => {
		new Anchor($(el));
	});

	const $footer = $('footer');
	const $main = $('main');
	const $sections = $main.find('section');

	if($sections.length % 2) {
		$footer.addClass('footer--dark');
	}
});
