import 'slick-carousel';

class Slider {
  constructor($el) {
    this.$el = $el;
    this.$slides = this.$el.find('[data-slider="slides"]');
    this.$prev = this.$el.find('[data-slider="prev"]');
    this.$next = this.$el.find('[data-slider="next"]');

    this.init();
  }

  init = () => {
    this.$slides.slick({
      variableWidth: true,
      prevArrow: this.$prev,
      nextArrow: this.$next,
    });
  }
}

export default Slider;